import React from 'react';

import Navbar from './components/Navbar';
import Header from './components/Header';
import Main from './components/Main';
// import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import Footer from './components/Footer';

import './App.scss';

export default () => (
  <div className="App">
    <Navbar />
    <Header />
    <Main />
    {/*<Portfolio />*/}
    <Contact />
    <Footer />
  </div>
);
