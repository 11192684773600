import React from 'react';

import styles from './Header.module.scss';

export default () => (
  <div className={`wrapper ${styles.Header}`}>
    <div className="page-header">
      <div className="page-header-image" />
      <div className="container">
        <h1 className="h2">Desenvolvimento de Soluções Tecnológicas</h1>
        <h2 className="h5">Aplicações Web, Mobile ou Desktop</h2>
      </div>
    </div>
  </div>
);