import React from 'react';

import styles from './Footer.module.scss';

export default () => (
  <footer className={styles.footer}>
    <div className="container">
      <div className="row">
        <div className="col-md-2">
          <img src="/img/logo.svg" alt="logo" />
        </div>
        <div className={`col-md ${styles.info}`}>
          <a href="mailto:contato@turconi.com.br">
            contato@turconi.com.br
          </a>
          <h5 class="mt-2">Porto Alegre, RS</h5>
          <p>© 2019 - Turconi Tecnologia - Todos os direitos reservados.</p>
        </div>
      </div>
    </div>
  </footer>
);