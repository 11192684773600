import React from 'react';

import Icons from './Icons';

import styles from './Contact.module.scss';

export default class Contact extends React.Component {

  state = {
    name: '',
    email: '',
    subject: '',
    message: '',
    status: 'invalid',
    errors: []
  };

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value}, () => {
      const valid = this.state.name && this.state.email && this.state.subject && this.state.message;
      const status = valid ? 'valid' : 'invalid';
      this.setState({status});
    });
  };

  handleClick = (event) => {
    event.preventDefault();
    const { status, errors, ...body } = this.state; 
    const request = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    };
    this.setState({status: 'loading'}, () => {
      if(this.state.status === 'loading') {
        fetch('/api/contact', request)
          .then(response => {
            if(response.ok) {
              this.setState({status: 'success'});
            } else {
              throw response;
            }
          })
          .catch(e => {
            if(e instanceof Response) {
              e.json().then(errors => {
                debugger;
                this.setState({
                  status: 'invalid',
                  errors: Object.keys(errors)
                });
              });
            } else {
              this.setState({status: 'valid'});
            }
          });
      }
    });
  };

  render() {
    const disabled = this.state.status !== 'valid';
    const errors = this.state.errors;
    return (
      <div id="section-contact" className={styles.contact}>
        <div className="container">
          <h4>Entre em contato</h4>
          <div className="row">
            <div className="col-md">
              <label>Seu nome:</label>
              <div className={`input-group ${errors.includes('name') ? 'has-error' : ''}`}>
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <Icons.faUser />
                  </span>
                </div>
                <input className="form-control form-control-danger" name="name" onChange={this.handleChange} />
                {errors.includes('name') && (
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Icons.faTimes />
                    </span>
                  </div>
                )}
              </div>
              <label>Seu e-mail:</label>
              <div className={`input-group ${errors.includes('email') ? 'has-error' : ''}`}>
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <Icons.faEnvelope />
                  </span>
                </div>
                <input type="email" className="form-control" name="email" onChange={this.handleChange} />
                {errors.includes('email') && (
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Icons.faTimes />
                    </span>
                  </div>
                )}
              </div>
              <label>Assunto:</label>
              <div className={`input-group ${errors.includes('subject') ? 'has-error' : ''}`}>
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <Icons.faComment />
                  </span>
                </div>
                <input className="form-control" name="subject" onChange={this.handleChange} />
                {errors.includes('subject') && (
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Icons.faTimes />
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md d-md-flex flex-md-column">
              <label>Mensagem:</label>
              <div className={`input-group h-100 ${errors.includes('message') ? 'has-error' : ''}`}>
                <textarea className="form-control" name="message" onChange={this.handleChange} />
              </div>
            </div>
          </div>
          <div className="text-right">
            {this.state.status === 'success' ? (
              <div className="btn btn-success btn-round">
                <Icons.faCheck className="mr-2" />
                MENSAGEM ENVIADA
              </div>
            ) : (
              <button className="btn btn-primary btn-round" disabled={disabled} onClick={this.handleClick}>
                {this.state.status === 'loading' && (
                  <Icons.faCircleNotch spin className="mr-2" />
                )}
                ENVIAR
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }


}