import React from 'react';

import NavbarToggler from './NavbarToggler';
import NavItem from './NavItem';

import styles from './Navbar.module.scss';

export default class Navbar extends React.Component {

  navRef = React.createRef();

  state = {
    show: false,
    style: {
      boxShadow: 'none',
      color: '#FFFFFF'
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.dispatchEvent(new Event('scroll'));
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const height = window.innerHeight / 2;
    const scrollTop = document.documentElement.scrollTop;
    const { bottom } = this.navRef.current.getBoundingClientRect();
    const alpha = Math.max(scrollTop - (height - bottom), 0);
    this.setState({
      style: {
        boxShadow: 'none',
        backgroundColor: `rgba(249, 99, 50, ${Math.min(alpha / height, 1)})`,
        color: '#FFFFFF'
      }
    })
  };

  handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const currentTarget = event.currentTarget;
    this.setState({show: false}, () => {
      requestAnimationFrame(() => {
        const { height } = this.navRef.current.getBoundingClientRect();
        const target = document.querySelector(currentTarget.hash);
        if(target) {
          window.scroll({
            top: target.offsetTop - height,
            left: 0,
            behavior: 'smooth'
          });
        }
      });
    });
  };

  handleNavbarToggle = () => {
    this.setState(({show}) => ({show: !show}));
  };

  render() {
    const show = this.state.show ? 'show' : '';
    return (
      <nav className="navbar navbar-expand-md fixed-top" style={this.state.style} ref={this.navRef}>
        <div className="container">
          <a className="navbar-brand" href="/">
            Turconi Tecnologia
          </a>
          <NavbarToggler onClick={this.handleNavbarToggle} />
          <div className={`collapse navbar-collapse justify-content-end ${show}`}>
            <ul className={`navbar-nav ${styles.NavbarNav}`}>
              {/*<NavItem href="#section-portfolio">
                Portfólio
              </NavItem>*/}
              <NavItem href="#section-main" onClick={this.handleClick}>
                Áreas de Atuação
              </NavItem>
              <NavItem href="#section-contact" onClick={this.handleClick}>
                Contato
              </NavItem>
              {/*<li className="nav-item">
                <a className="nav-link" href="javascript:void(0)">
                  <i className="now-ui-icons arrows-1_cloud-download-93"></i>
                  <p>Download</p>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://www.linkedin.com/in/eduardo-turconi" target="_blank" rel="noopener noreferrer">
                  <Icons.faLinkedin />
                  <p className="d-lg-none d-xl-none">LinkedIn</p>
                </a>
              </li>*/}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}