import React from 'react';

import Icon from './Icons';

import styles from './NavbarToggler.module.scss';

export default ({onClick}) => (
  <button className={styles.NavbarToggler} type="button" onClick={onClick}>
    <Icon.faBars />
  </button>
);