import React from 'react';

/*export default class NavItem extends React.PureComponent {

  handleClick = (event) => {
    const { height } = this.props.navRef.current.getBoundingClientRect();
    event.preventDefault();
    event.stopPropagation();
    const target = document.querySelector(this.props.href);
    if(target) {
      window.scroll({
        top: target.offsetTop - height,
        left: 0,
        behavior: 'smooth'
      });
    }
  };

  render() {
    return (
      <li className="nav-item">
        <a className="nav-link" href={this.props.href} onClick={this.handleClick}>
          <p>{this.props.children}</p>
        </a>
      </li>
    );
  }
}*/

export default ({href, children, onClick}) => (
  <li className="nav-item">
    <a className="nav-link" href={href} onClick={onClick}>
      <p>{children}</p>
    </a>
  </li>
);