import React from 'react';

import styles from './Main.module.scss';

export default () => (
  <div id="section-main" className={styles.Main}>
    <div className="container">
      <h3>Áreas de atuação</h3>
      <ul className={styles.MainList}>
        <li className={styles.MainListItem}>
          Aplicações web utilizando tecnologias como PHP (Laravel) e frameworks JavaScript, como React, Angular e Vue
        </li>
        <li className={styles.MainListItem}>
          Aplicativos para dispositivos móveis utilizando Flutter, ou linguagens nativas Android e iOS
        </li>
        <li className={styles.MainListItem}>
          Programas para servidores ou desktops com implementação nativa, como serviços ou scripts batch
        </li>
        <li className={styles.MainListItem}>
          Desenvolvimento de sites responsivos e landing pages
        </li>
        <li className={styles.MainListItem}>
          Configuração de servidores Linux ou Windows
        </li>
        <li className={styles.MainListItem}>
          Modelagem e configuração de bancos de dados SQL e NoSQL
        </li>
      </ul>
    </div>
  </div>
);