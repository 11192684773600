import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faBars, faCheck, faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faComment, faEnvelope, faUser } from '@fortawesome/free-regular-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

const icons = {
  faBars,
  faCheck,
  faCircleNotch,
  faComment,
  faEnvelope,
  faLinkedin,
  faTimes,
  faUser
};

export default new Proxy(icons, {
  get(target, name) {
    if(name in target) {
      return (props) => (
        <FontAwesomeIcon icon={icons[name]} {...props} />
      );
    } else {
      throw new Error('FontAwesome icon is not imported');
    }
  }
});
